import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Header, Popup, Token, Widget } from '@revolut/ui-kit'
import { getEmployeeShifts, scheduleShiftsRequests } from '@src/api/attendance'
import {
  EmployeeShiftDetailsInterface,
  EmployeeShiftsStatsInterface,
} from '@src/interfaces/attendance'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import {
  LargeWeeklyCalendar,
  LargeWeeklyCalendarEventInterface,
} from '@src/components/LargeWeeklyCalendar'
import { useTable } from '@components/Table/hooks'
import Form from '@src/features/Form/Form'
import { selectUser } from '@src/store/auth/selectors'
import { utcToLocalDate } from '@src/utils/timezones'
import { ShiftForm } from './components/ShiftForm'
import {
  approvalStatusIdToIconColor,
  approvalStatusIdToBgColor,
  getDateWithTime,
  getRequestDayRange,
} from './helpers'

interface Props {
  data: EmployeeTimeOffRequestsCalendarInterface
  employeeId: number
  onWeekSwitch: (startDate: Date, endDate: Date) => void
}

export const TimeOffLargeWeeklyCalendar = ({ data, employeeId, onWeekSwitch }: Props) => {
  const user = useSelector(selectUser)
  const isThisUser = employeeId === user.id
  const [selectedEvent, setSelectedEvent] = useState<LargeWeeklyCalendarEventInterface>()

  const table = useTable<
    EmployeeShiftDetailsInterface,
    undefined,
    EmployeeShiftsStatsInterface
  >({ getItems: getEmployeeShifts(employeeId) }, [
    {
      columnName: 'approval',
      filters: isThisUser
        ? []
        : [
            { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
            { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
            { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
          ],
    },
  ])

  const shifts = useMemo(() => {
    return table.data.flatMap(parent =>
      parent.children.flatMap(shift => {
        return {
          id: String(shift.id),
          end: getDateWithTime(
            utcToLocalDate(parent.date),
            shift.time_range?.end || '00:00',
          ),
          start: getDateWithTime(
            utcToLocalDate(parent.date),
            shift.time_range?.start || '00:00',
          ),
          title: shift.type.name,
          backgroundColor: Token.color.greyTone2,
        }
      }),
    )
  }, [table.data])

  const nonWorkingDays = useMemo(() => {
    return (
      data?.non_working_days?.map(date => {
        const day = utcToLocalDate(date)
        const nextDay = new Date(day)
        nextDay.setDate(day.getDate() + 1)

        return {
          end: nextDay,
          start: day,
          isReadOnly: true,
          title: 'Non working',
          backgroundColor: Token.color.greyTone8,
        }
      }) || []
    )
  }, [data])

  const publicHolidays = useMemo(() => {
    return (
      data?.public_holidays?.map(holiday => {
        const day = utcToLocalDate(holiday.day)
        const nextDay = new Date(day)
        nextDay.setDate(day.getDate() + 1)

        return {
          end: nextDay,
          start: day,
          isReadOnly: true,
          title: 'Bank holiday',
          backgroundColor: Token.color.greyTone8,
        }
      }) || []
    )
  }, [data])

  const timeOffRequests = useMemo(() => {
    return (
      data?.time_off_requests?.map(request => {
        const range = getRequestDayRange(request)
        return {
          start: range?.start,
          end: range?.end,
          isReadOnly: true,
          title: request.category.name,
          color: approvalStatusIdToIconColor(request.approval_status.id),
          backgroundColor: approvalStatusIdToBgColor(request.approval_status.id, true),
        }
      }) || []
    )
  }, [data])

  return (
    <>
      <Widget p="s-16" height="90vh">
        <LargeWeeklyCalendar
          events={[...nonWorkingDays, ...publicHolidays, ...timeOffRequests, ...shifts]}
          onAddEvent={event => {
            setSelectedEvent(event)
          }}
          onChangeEvent={event => {
            setSelectedEvent({ id: event.event.id, ...event.changes })
          }}
          onClickEvent={({ event }) => {
            setSelectedEvent(event)
          }}
          onSwitchWeek={onWeekSwitch}
        />
      </Widget>

      <Popup open={!!selectedEvent} onClose={() => setSelectedEvent(undefined)}>
        <Header>
          <Header.Title>{selectedEvent?.id ? 'Edit' : 'Create'} event</Header.Title>
        </Header>
        <Form
          api={scheduleShiftsRequests}
          disableLocalStorageCaching
          forceParams={{
            employeeId: String(employeeId),
            id: selectedEvent?.id ? selectedEvent.id : undefined,
            new: selectedEvent?.id ? undefined : 'new',
          }}
        >
          <ShiftForm
            calendarEvent={selectedEvent}
            onAfterSubmit={() => {
              setSelectedEvent(undefined)
              table.refresh()
            }}
            onCancel={() => setSelectedEvent(undefined)}
          />
        </Form>
      </Popup>
    </>
  )
}
