import React from 'react'
import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'
import { IdAndName } from '@src/interfaces'

import { ViewType, ScoreType } from '../types'
import { ResultCategories } from './ResultCategories'
import { ResultComments } from './ResultComments'
import { ResultQuestions } from './ResultQuestions'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'

interface Props {
  view: ViewType
  scoreType: ScoreType
  selectedRound?: IdAndName
  surveyId: number
}
export const TableComponent = ({ selectedRound, ...rest }: Props) => {
  if (!selectedRound) {
    return <TableBodySkeleton rowHeight="large" />
  }
  return (
    <Switch>
      <Route exact path={ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.COMMENTS}>
        <ResultComments {...rest} selectedRound={selectedRound} />
      </Route>
      <Route exact path={ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.QUESTIONS}>
        <ResultQuestions {...rest} selectedRound={selectedRound} />
      </Route>
      <Route>
        <ResultCategories {...rest} selectedRound={selectedRound} />
      </Route>
    </Switch>
  )
}
