import React, { useEffect } from 'react'
import format from 'date-fns/format'
import { Button, InputGroup, Popup } from '@revolut/ui-kit'
import { ScheduleShiftInterface } from '@src/interfaces/attendance'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { LargeWeeklyCalendarEventInterface } from '@src/components/LargeWeeklyCalendar'
import { getDateFromEventDate } from '@src/components/LargeWeeklyCalendar/LargeWeeklyCalendar'
import { getFormattedTime } from '@src/components/LargeWeeklyCalendar/date'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface ShiftFormProps {
  calendarEvent?: LargeWeeklyCalendarEventInterface
  onAfterSubmit: VoidFunction
  onCancel: VoidFunction
}

export const ShiftForm = ({ calendarEvent, onAfterSubmit, onCancel }: ShiftFormProps) => {
  const { values } = useLapeContext<ScheduleShiftInterface>()

  const shiftTypeOptions = [
    { id: 'break', name: 'Break' },
    { id: 'work', name: 'Work' },
  ].map(option => ({
    label: option.name,
    value: option,
  }))

  useEffect(() => {
    const startEventDate = getDateFromEventDate(calendarEvent?.start)
    const endEventDate = getDateFromEventDate(calendarEvent?.end)

    if (startEventDate) {
      values.date = format(startEventDate, 'yyyy-MM-dd')
      values.start_time = getFormattedTime(startEventDate)
    }

    if (endEventDate) {
      values.end_time = getFormattedTime(endEventDate)
    }
  }, [calendarEvent])

  return (
    <>
      <InputGroup>
        <LapeDatePickerInput
          label="Date"
          name="date"
          onChange={date => {
            if (date) {
              values.date = format(date, 'yyyy-MM-dd')
            }
          }}
          required
        />
        <InputGroup variant="horizontal">
          <LapeNewInput name="start_time" label="Start time" required type="time" />
          <LapeNewInput name="end_time" label="End time" required type="time" />
        </InputGroup>
        <LapeRadioSelectInput label="Shift type" name="type" options={shiftTypeOptions} />
      </InputGroup>
      <Popup.Actions>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          onAfterSubmit={onAfterSubmit}
          successText={
            values?.id
              ? 'Event was successfully updated'
              : 'Event was successfully created'
          }
          useValidator
        >
          Save
        </NewSaveButtonWithPopup>
      </Popup.Actions>
    </>
  )
}
