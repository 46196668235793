import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import Contracts from '@src/pages/Forms/EmployeeContracts'
import RightToWork from '@src/pages/Forms/EmployeeRightToWork'
import InterviewFeedback from '@src/pages/Forms/InterviewFeedback'
import Candidate from '@src/pages/Forms/Candidate'
import { Screenings } from '@src/pages/Forms/ScreeningForm'
import { EmployeeForm } from '@src/pages/EmployeeProfile'
import AnytimeFeedback from '@src/pages/Forms/AnytimeFeedback'
import ProbationTemplate from '@src/pages/Forms/ProbationTemplate'
import ProbationTemplateView from '@src/pages/Forms/ProbationTemplate/View'
import { Team } from '@src/pages/Team'
import { Department } from '@src/pages/Department'
import Function from '@src/pages/Forms/FunctionsForm'
import Company from '@src/pages/Forms/Company'
import Roles from '@src/pages/Forms/RoleForm'
import DynamicGroups from '@src/pages/Forms/DynamicGroups'
import Document from '@src/pages/Forms/Document'
import DocumentsRequests from '@src/pages/Forms/DocumentRequest'
import DocumentGenerate from '@src/pages/Forms/DocumentGenerate/DocumentGenerate'
import Kpi from '@src/pages/Forms/KpiForm/'
import ProbationOverview from '@src/pages/Forms/ProbationOverview'
import Page404 from '@src/pages/Page404/Page404'
import AccessRequestRouter from '@src/pages/Forms/AccessRequestsForm'
import EmployeePerformance from '@src/pages/Forms/EmployeePerformance'
import EmployeePerformanceViewForm from '@src/pages/Forms/EmployeePerformanceView'
import ProbationForm from '@src/pages/Forms/Probation'
import ProbationViewForm from '@src/pages/Forms/ProbationView'
import ProbationFinalResult from '@src/pages/Forms/FinalResultForm/General'
import UpwardsReview from '@src/pages/Forms/EmployeePerformance/Upwards'
import UpwardsReviewViewForm from '@src/pages/Forms/EmployeePerformance/UpwardsView'
import InterviewScorecardTemplate from '@src/pages/Forms/InterviewScorecardTemplate'
import EligiblePeopleEdit from '@src/pages/Forms/EligiblePeopleEdit'
import CompanyGoals from '@src/pages/Forms/CompanyGoals'
import KPIWeights from '@src/pages/Forms/KpiWeightsForm/General'
import CompanyGoalsWeights from '@src/pages/Forms/CompanyGoalsWeightsForm/General'
import { KpiTemplateForm } from '@src/pages/Forms/KpiTemplates/KpiTemplate/General'
import KPIRule from '@src/pages/Forms/KpiTemplates/KpiRule/General'
import CustomFields from '@src/pages/Forms/CustomFieldsForm/General'
import NotificationsTemplate from '@src/pages/Forms/NotificationTemplate'
import Requisition from '@src/pages/Forms/RequisitionForm'
import LinkRevolutAccountForm from '@src/pages/Forms/LinkRevolutAccountForm'
import LabelForm from '@src/pages/Forms/Label/General'
import LocationForm from '@src/pages/Forms/LocationForm/General'
import AssignKPIs from '@src/pages/Forms/AssignKPIs'
import ApproveKPIs from '@src/pages/Forms/ApproveKPIs'
import HiringStage from '@src/pages/Forms/HiringStage'
import ImportExternalEmployees from '@src/pages/Forms/ImportData/ExternalEmployees/ImportExternalEmployees'
import { ImportEmployees } from '@src/pages/Forms/ImportData/Employees'
import { ImportDocuments } from '@src/pages/Forms/ImportData/Documents'
import ImportDeparmentBudget from '@src/pages/Forms/ImportData/DepartmentBudget'
import ImportBonuses from '@src/pages/Forms/ImportData/Bonuses'
import ReviewCycleGeneral from '@src/pages/Forms/ReviewCycle/General'
import ReviewCycleGeneralInfoForm from '@src/pages/Forms/ReviewCycle/GeneralInfoForm'
import ReviewCycleTimelineForm from '@src/pages/Forms/ReviewCycle/TimelineForm'
import { EligibleEmployees } from '@src/pages/Forms/ReviewCycle/EligibleEmployeesForm'
import { AdditionalScorecardsForm } from '@src/pages/Forms/ReviewCycle/AdditionalScorecards'
import GradeLogicForm from '@src/pages/Forms/ReviewCycle/GradeLogicForm'
import PageSkill from '@src/pages/Forms/SkillForm'
import PageCultureValue from '@src/pages/Forms/CultureValueForm'
import DocumentTemplate from '@src/pages/Forms/DocumentsTemplate'
import DocumentRule from '@src/pages/Forms/DocumentsRule'
import DocumentUpload from '@src/pages/Forms/DocumentUpload'
import DocumentEmbedded from '@src/pages/Forms/DocumentSigning/Embedded'
import DocumentDocusign from '@src/pages/Forms/DocumentSigning/Docusign'
import DocumentCategory from '@src/pages/Forms/DocumentCategory/DocumentCategory'
import Seniority from '@src/pages/Forms/SeniorityForm/General'
import EntityForm from '@src/pages/Forms/EntityForm/General'
import SalarySacrifice from '@src/pages/Forms/EmployeeSalarySacrifice/General'
import { RecruitmentGroups } from '@src/pages/Forms/RecruitmentGroupsForm'
import ImportKPIs from '@src/pages/Forms/ImportData/KPIs'
import WhitelistForm from '@src/pages/Forms/WhitelistForm/index'
import EmployeeChangeRequest from '@src/pages/Forms/EmployeeChangeRequest'
import EmployeeTimeOffPolicyAssignment from '@src/pages/Forms/EmployeeTimeOffPolicyAssignment'
import EmployeeTimeOffPolicyUnassignment from '@src/pages/Forms/EmployeeTimeOffPolicyAssignment/Unassign'
import EmployeeTimeOffRequest from '@src/pages/Forms/EmployeeTimeOffRequest'
import EmployeeTimeOffAbsence from '@src/pages/Forms/EmployeeTimeOffRequest/Absence'
import Benchmark from '@src/pages/Forms/Benchmark'
import BudgetPool from '@src/pages/Forms/BudgetPool'
import BudgetReviewCycle from '@src/pages/Forms/BudgetReviewCycle'
import SchedulingPolicy from '@src/pages/Forms/SchedulingPolicy'
import SchedulingPolicyShift from '@src/pages/Forms/SchedulingPolicyShift'
import ScheduleShift from '@src/pages/Forms/ScheduleShift'
import DefaultWorkingHours from '@src/pages/Forms/DefaultWorkingHours/DefaultWorkingHours'
import Benefits from '@src/pages/Forms/Benefits'
import EmployeeBenefit from '@src/pages/Forms/EmployeeBenefit'
import OnboardingTimeline from '@src/pages/Forms/OnboardingTimeline'
import OnboardingSendEmail from '@src/pages/Forms/OnboardingSendEmail/General'
import { Offboarding } from '@src/pages/Forms/Offboarding/Offboarding'
import TimeOffRegimes from '@src/pages/Forms/TimeOffRegimes'
import TimeOffPolicies from '@src/pages/Forms/TimeOffPolicies'
import TimeOffBalances from '@src/pages/Forms/TimeOffBalances'
import { TimeOffCategories } from '@src/pages/Forms/TimeOffCategories'
import JobDescriptionForm from '@src/pages/Forms/JobPosting/index'
import { JobPostingFlow } from '@src/features/JobPostingFlow'
import CommitteeResultForm from '@src/pages/Forms/CommitteeResultForm/index'
import AdditionalSettingsForm from '@src/pages/EmployeeProfile/Preview/Performance/AdditionalSettingsForm'
import NewCandidate from '@src/pages/Forms/NewCandidate/index'
import ReferCandidate from '@src/pages/Forms/ReferCandidate/index'
import { ReferralBonus } from '@src/pages/Forms/ReferralBonus'
import JobPostingAutomationRule from '@src/pages/Forms/JobPostingAutomationRule'
import EmailTemplate from '@src/pages/Forms/EmailTemplate'
import EmployeeEmailTemplate from '@src/pages/Forms/EmployeeEmailTemplate'
import SendEmail from '@src/pages/Forms/SendEmail'
import { SendEmployeeEmail } from '@src/pages/Forms/SendEmployeeEmail'
import KPITarget from '@src/features/FormTabs/Kpi/KPITargets'
import PromotionNomination from '@src/pages/Forms/PromotionNominationForm'
import ApplicationCandidate from '@src/pages/Forms/ApplicationCandidate'
import OfferEmailPlaceholder from '@src/pages/Forms/OfferPlaceholder'
import OfferTemplate from '@src/pages/Forms/OfferTemplate'
import OfferCreation from '@src/pages/Forms/OfferCreation'
import FaqCategory from '@src/pages/Forms/FaqCategory/General'
import Leadership from '@src/pages/Forms/Leadership/General'
import CandidateAddDocument from '@src/pages/Forms/CandidateAddDocument'
import DataRetentionSettings from '@src/pages/Forms/DataRetentionSettings'
import AnonymizeCandidate from '@src/pages/Forms/AnonymizeCandidate'
import AccessManagementUser from '@src/pages/Forms/AccessManagementUser'
import AccessManagementGroup from '@src/pages/Forms/AccessManagementGroup'
import AssignUserToPermissionGroup from '@src/pages/Forms/AssignUserToPermissionGroup'
import AssignPermissionGroupToUser from '@src/pages/Forms/AssignPermissionGroupToUser'
import AddServiceAccount from '@src/pages/Forms/AddServiceAccount'
import AssignUserToPermission from '@src/pages/Forms/AssignUserToPermission'
import EmployeeResignation from '@src/pages/Forms/EmployeeResignation'
import AccessManagementPermission from '@src/pages/Forms/AccessManagementPermission'
import AssignUserToPermissionGroups from '@src/pages/Forms/AssignUserToPermissionGroups'
import CVScreening from '@src/pages/Forms/CVScreening/CVScreening'
import CommunicationGroups from '@src/pages/Forms/CommunicationGroups'
import EditCompanyBudget from '@src/pages/Forms/Budgets/EditCompanyBudget/EditCompanyBudget'
import EditDepartmentBudget from '@src/pages/Forms/Budgets/EditDepartmentBudget/EditDepartmentBudget'
import { ViewCustomBudgetPool } from '@src/pages/Forms/Budgets/ViewCustomBudgetPool/ViewCustomBudgetPool'
import AddInterviewRound from '@src/pages/Forms/AddInterviewRound/AddInterviewRound'
import TeamSettingsPage from './TeamForm/Settings'
import DepartmentSettingsPage from './DepartmentForm/Settings'
import FunctionsSettingsPage from './FunctionsForm/Settings'
import RolesSettingsPage from './RoleForm/General'
import SpecialisationRole from '@src/pages/Forms/SpecialisationForm'
import SpecialisationSettings from '@src/pages/Forms/SpecialisationForm/General'
import Banner from '@src/pages/Forms/Banner/Banner'
import MeetingsWith from '@src/pages/Forms/MeetingsWith'
import { PipV2 } from '@src/pages/Forms/PipV2'
import { PipOverview } from '@src/pages/Forms/PipOverview'
import PipReviewForm from '@src/pages/Forms/PipReview'
import PipViewForm from '@src/pages/Forms/PipView'
import PipCommittee from '@src/pages/Forms/PipCommittee'
import EmployeesBulkEdit from './EmployeesBulkEdit'
import ContractsBulkEdit from './ContractsBulkEdit'
import DataRetentionForm from './DataRetentionForm'
import SlackIntegration from '@src/pages/Hub/Integrations/Slack'
import { ContractType } from '@src/pages/Forms/ContractType'
import JiraIntegration from '@src/pages/Hub/Integrations/Jira'
import { GoogleIntegration } from '@src/pages/Hub/Integrations/Google'
import DocuSignIntegration from '@src/pages/Hub/Integrations/DocuSign'
import { DocuSignConfirmation } from '@src/pages/Hub/Integrations/DocuSign/Confirmation'
import RoadmapForm from '@src/pages/Forms/RoadmapForm'
import UserPreferences from '@src/pages/UserPreferences'
import QueryForm from '@src/pages/Forms/QueryForm'
import ConnectionForm from '@src/pages/Forms/ConnectionForm'
import ReportForm from '@src/pages/Forms/DataAnalyticsReportForm'
import ReportViewForm from '@src/pages/Forms/DataAnalyticsReportViewForm'
import DashboardForm from '@src/pages/Forms/DataAnalyticsDashboardForm'
import InternalDashboardForm from '@src/pages/Forms/DataAnalyticsInternalDashboardForm'
import DashboardViewForm from '@src/pages/Forms/DataAnalyticsDashboardViewForm'
import { SendActivationEmails } from '@src/pages/Forms/SendActivationEmails/SendActivationEmails'
import { UpdateOrganisationStructure } from '@src/pages/Forms/UpdateOrganisationStructure/UpdateOrganisationStructure'
import { OptionExercise } from './OptionExercise'
import RequestAccess from '@src/pages/Forms/RequestAccess'
import { ProbationLayoutGoals } from '@src/pages/Forms/ProbationOverview/ProbationGoals/ProbationLayoutGoals'
import { PerformanceLayoutHistory } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceLayoutHistory'
import { ImportTimeOff } from '@src/pages/Forms/ImportData/TimeOff'
import { ImportTimeOffBalancesFlow } from '@src/pages/Forms/ImportData/TimeOff/ImportTimeOffBalancesV2'
import { GoalRouterWithForm } from '@src/pages/Forms/GoalForm'
import { EmployeePerformanceLayout } from '@src/pages/Forms/EmployeePerformanceLayout'
import { EmployeePerformanceViewLayout } from '@src/pages/Forms/EmployeePerformanceViewLayout'
import { UpwardsReviewPage } from '@src/pages/EmployeeProfile/Preview/Performance/Upwards/UpwardsReviewPage'
import { CvScreeningSummary } from '@src/pages/CVScreeningSummary/CVScreeningSummary'
import { PayrollDefinitionsBulkEdit } from '@src/apps/People/Payroll/Paygroup/PayrollDefinitionsBulkEdit'
import { ImportCompensationBands } from '@src/pages/Forms/ImportData/CompensationBands'
import { CompensationBandsBulkEdit } from '@src/pages/Forms/CompensationBandsBulkEdit'
import { ImportContracts } from '@src/pages/Forms/ImportData/Contracts'
import { ProbationLayout } from '@src/pages/Forms/ProbationLayout'
import { ProbationViewLayout } from '@src/pages/Forms/ProbationViewLayout'
import { DocumentsCollectionForm } from '@src/pages/Forms/DocumentsCollection'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { PipLayout } from '@src/pages/Forms/PipLayout'
import { PipViewLayout } from '@src/pages/Forms/PipViewLayout'
import { ImportCandidates } from '@src/pages/Forms/ImportData/Candidates'
import { EmployeesBulkImport } from '@src/pages/Forms/EmployeesBulkImport'
import { EmployeesOrgChart } from '@src/pages/Forms/EmployeesOrgChart'
import { UpwardsLayout } from '@src/pages/Forms/UpwardsLayout'
import { UpwardsViewLayout } from '@src/pages/Forms/UpwardsViewLayout'
import { BookingLinksPage } from '@src/features/BookingLinks/Recruiter/BookingLinksPage'
import { CRMPage } from '@src/pages/Recruitment/RecruitmentSubTabs/CRM/CRMPage'
import { BulkActionsPage } from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActionsPage'
import { EmailTemplatesPage } from '@src/pages/Recruitment/RecruitmentSubTabs/Email Templates/EmailTemplatesPage'
import { StagesBankPage } from '@src/pages/Recruitment/RecruitmentSubTabs/Hiring/StagesBankPage'
import { InterviewScorecardTemplatesPage } from '@src/pages/Recruitment/RecruitmentSubTabs/InterviewScorecardTemplates/InterviewScorecardTemplatesPage'
import { OffersPage } from '@src/pages/Forms/OfferForms/OffersPage'
import { ImportGoals } from '@src/pages/Forms/ImportData/Goals'
import { SpecialisationHiringProcess } from '@src/pages/Forms/SpecialisationHiringProcess'
import { EligibleEmployeesList } from '@src/pages/Performance/SupportTool/EligibleEmployeesList'
import { ProbationReviewsTable } from '@src/pages/Performance/Reviews/ProbationPipTables/ProbationReviewsTable'
import { PipReviewsTable } from '@src/pages/Performance/Reviews/ProbationPipTables/PipReviewsTable'
import { EmployeeOnboardingTemplate } from '@src/pages/Forms/EmployeeOnboardingTemplate'
import { EngagementForm } from './Engagement'
import { DashboardSelect } from '@src/pages/Forms/DataAnalyticsDashboardForm/DashboardSelect'
import { DataAnalyticsInternalDashboardView } from '@src/pages/Forms/DataAnalyticsInternalDashboardView'
import { MicrosoftIntegration } from '@src/pages/Hub/Integrations/Microsoft/MicrosoftIntegration'
import { InterviewAvailabilityTablePage } from '@src/features/InterviewAvailability/InterviewAvailabilityTablePage'

const NewFormSwitch = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.CUSTOM_FIELDS.ANY}>
        <CustomFields />
      </Route>
      <Route exact path={ROUTES.FORMS.KPI.ANY}>
        <Kpi />
      </Route>
      <Route exact path={ROUTES.FORMS.ENGAGEMENT.ANY}>
        <EngagementForm />
      </Route>
      <Route exact path={ROUTES.FORMS.GOAL.ANY}>
        <GoalRouterWithForm />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANY}>
        <AnytimeFeedback />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION.TEMPLATE}>
        <ProbationTemplate />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION.TEMPLATE_VIEW}>
        <ProbationTemplateView />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.ANY}>
        <PerformanceLayoutHistory />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.UPWARDS}>
        <UpwardsReviewPage />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.ANY}>
        <EmployeeForm />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.ANY}>
        <EmployeeChangeRequest />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.ANY}>
        <EmployeeResignation />
      </Route>
      <Route exact path={ROUTES.FORMS.TEAM.SETTINGS}>
        <TeamSettingsPage />
      </Route>
      <Route exact path={ROUTES.FORMS.TEAM.ANY}>
        <Team />
      </Route>
      <Route exact path={ROUTES.FORMS.LINKED_ACCOUNT.ANY}>
        <LinkRevolutAccountForm />
      </Route>
      <Route exact path={ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.ANY}>
        <InterviewScorecardTemplate />
      </Route>
      <Route
        exact
        path={[ROUTES.FORMS.ACCESS_REQUESTS.ANY, ROUTES.FORMS.TEAM_ACCESS_REQUESTS.ANY]}
      >
        <AccessRequestRouter />
      </Route>
      <Route exact path={ROUTES.FORMS.DEPARTMENT.SETTINGS}>
        <DepartmentSettingsPage />
      </Route>
      <Route exact path={ROUTES.FORMS.DEPARTMENT.ANY}>
        <Department />
      </Route>
      <Route exact path={ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.ANY}>
        <UpdateOrganisationStructure />
      </Route>
      <Route exact path={ROUTES.FORMS.HIRING_STAGES.ANY}>
        <HiringStage />
      </Route>
      <Route exact path={ROUTES.FORMS.COMPANY.ANY}>
        <Company />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_EXTRA_SETTINGS.ANY}>
        <AdditionalSettingsForm />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_CONTRACT.ANY}>
        <Contracts />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.ANY}>
        <RightToWork />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.ANY}>
        <EmployeeTimeOffPolicyAssignment />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_UNASSIGNMENT.ANY}>
        <EmployeeTimeOffPolicyUnassignment />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.ANY}>
        <EmployeeTimeOffRequest />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_ABSENCE.ANY}>
        <EmployeeTimeOffAbsence />
      </Route>
      <Route exact path={ROUTES.FORMS.ROLE.GENERAL}>
        <RolesSettingsPage />
      </Route>
      <Route exact path={ROUTES.FORMS.ROLE.ANY}>
        <Roles />
      </Route>
      <Route exact path={ROUTES.FORMS.SCREENING.ANY}>
        <Screenings />
      </Route>
      <Route exact path={ROUTES.FORMS.SCHEDULING_POLICY.ANY}>
        <SchedulingPolicy />
      </Route>
      <Route exact path={ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.ANY}>
        <SchedulingPolicyShift />
      </Route>
      <Route exact path={ROUTES.FORMS.SCHEDULE_SHIFT.ANY}>
        <ScheduleShift />
      </Route>
      <Route exact path={ROUTES.FORMS.DEFAULT_WORKING_HOURS.ANY}>
        <DefaultWorkingHours />
      </Route>
      <Route exact path={ROUTES.FORMS.ONBOARDING_TIMELINE.ANY}>
        <OnboardingTimeline />
      </Route>
      <Route exact path={ROUTES.FORMS.ONBOARDING_SEND_EMAIL.ANY}>
        <OnboardingSendEmail />
      </Route>
      <Route exact path={ROUTES.FORMS.OFFBOARDING.ANY}>
        <Offboarding />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_SALARY_SACRIFICE.ANY}>
        <SalarySacrifice />
      </Route>
      <Route exact path={ROUTES.FORMS.NOTIFICATIONS.ANY}>
        <InternalRedirect to={ROUTES.MAIN} />
      </Route>
      <Route exact path={ROUTES.FORMS.NOTIFICATION_TEMPLATE.ANY}>
        <NotificationsTemplate />
      </Route>
      <Route exact path={ROUTES.FORMS.SEND_ACTIVATION_EMAILS}>
        <SendActivationEmails />
      </Route>
      <Route exact path={ROUTES.FORMS.BENCHMARK.ANY}>
        <Benchmark />
      </Route>
      <Route exact path={ROUTES.FORMS.BUDGET_POOL.ANY}>
        <BudgetPool />
      </Route>
      <Route exact path={ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.ANY}>
        <BudgetReviewCycle />
      </Route>
      <Route exact path={ROUTES.FORMS.EDIT_COMPANY_BUDGET}>
        <EditCompanyBudget />
      </Route>
      <Route exact path={ROUTES.FORMS.EDIT_DEPARTMENT_BUDGET}>
        <EditDepartmentBudget />
      </Route>
      <Route exact path={ROUTES.FORMS.VIEW_CUSTOM_BUDGET_POOL}>
        <ViewCustomBudgetPool />
      </Route>
      <Route exact path={ROUTES.FORMS.BENEFITS.ANY}>
        <Benefits />
      </Route>
      <Route
        exact
        path={[
          ROUTES.FORMS.EMPLOYEE_BENEFIT.ANY,
          ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.ANY,
        ]}
      >
        <EmployeeBenefit />
      </Route>
      <Route exact path={ROUTES.FORMS.TIME_OFF_REGIMES.ANY}>
        <TimeOffRegimes />
      </Route>
      <Route exact path={ROUTES.FORMS.TIME_OFF_POLICY.ANY}>
        <TimeOffPolicies />
      </Route>
      <Route exact path={ROUTES.FORMS.TIME_OFF_CATEGORIES.ANY}>
        <TimeOffCategories />
      </Route>
      <Route exact path={ROUTES.FORMS.TIME_OFF_BALANCE.ANY}>
        <TimeOffBalances />
      </Route>
      <Route exact path={ROUTES.FORMS.EMAIL_TEMPLATE.ANY}>
        <EmailTemplate />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.ANY}>
        <EmployeeEmailTemplate />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_RETENTION_SETTINGS.ANY}>
        <DataRetentionSettings />
      </Route>
      <Route exact path={ROUTES.FORMS.LABEL.ANY}>
        <LabelForm />
      </Route>
      <Route exact path={ROUTES.FORMS.LOCATION.ANY}>
        <LocationForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DYNAMIC_GROUPS.ANY}>
        <DynamicGroups />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT.ANY}>
        <Document />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_EMBEDDED.ANY}>
        <DocumentEmbedded />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_DOCUSIGN.ANY}>
        <DocumentDocusign />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_UPLOAD.ANY}>
        <DocumentUpload />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_REQUEST.ANY}>
        <DocumentsRequests />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_GENERATE.ANY}>
        <DocumentGenerate />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_TEMPLATE.ANY}>
        <DocumentTemplate />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_COLLECTION.ANY}>
        <DocumentsCollectionForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_RULE.ANY}>
        <DocumentRule />
      </Route>
      <Route exact path={ROUTES.FORMS.DOCUMENT_CATEGORIES.ANY}>
        <DocumentCategory />
      </Route>
      <Route exact path={ROUTES.FORMS.FAQ_CATEGORY.ANY}>
        <FaqCategory />
      </Route>
      <Route exact path={ROUTES.FORMS.BANNER.ANY}>
        <Banner />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT}>
        <EmployeePerformanceLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW_LAYOUT}>
        <EmployeePerformanceViewLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_PERFORMANCE.ANY}>
        <EmployeePerformance />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.ANY}>
        <EmployeePerformanceViewForm />
      </Route>
      <Route exact path={ROUTES.FORMS.ENTITY.ANY}>
        <EntityForm />
      </Route>
      <Route exact path={ROUTES.FORMS.UPWARDS_REVIEW_LAYOUT}>
        <UpwardsLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.UPWARDS_REVIEW_VIEW_LAYOUT}>
        <UpwardsViewLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.UPWARDS_REVIEW.ANY}>
        <UpwardsReview />
      </Route>
      <Route exact path={ROUTES.FORMS.UPWARDS_REVIEW_VIEW.ANY}>
        <UpwardsReviewViewForm />
      </Route>
      <Route exact path={ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PROBATION}>
        <ProbationReviewsTable />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_GOALS}>
        <ProbationLayoutGoals />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_OVERVIEW.ANY}>
        <ProbationOverview />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_REVIEW_LAYOUT}>
        <ProbationLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_REVIEW_VIEW_LAYOUT}>
        <ProbationViewLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_REVIEW.ANY}>
        <ProbationForm />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_REVIEW_VIEW.ANY}>
        <ProbationViewForm />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_FINAL_RESULT.ANY}>
        <ProbationFinalResult />
      </Route>
      <Route exact path={ROUTES.FORMS.INTERVIEW_FEEDBACK.ANY}>
        <InterviewFeedback />
      </Route>
      <Route exact path={ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PIP}>
        <PipReviewsTable />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP.ANY}>
        <PipV2 />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP_OVERVIEW.ANY}>
        <PipOverview />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP_REVIEW_LAYOUT}>
        <PipLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP_REVIEW_VIEW_LAYOUT}>
        <PipViewLayout />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP_REVIEW.ANY}>
        <PipReviewForm />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP_REVIEW_VIEW.ANY}>
        <PipViewForm />
      </Route>
      <Route exact path={ROUTES.FORMS.PIP_COMMITTEE_RESULT.ANY}>
        <PipCommittee />
      </Route>
      <Route exact path={ROUTES.FORMS.ANONYMIZE_CANDIDATE.ANY}>
        <AnonymizeCandidate />
      </Route>
      <Route exact path={ROUTES.FORMS.CANDIDATE.ANY}>
        <Candidate />
      </Route>
      <Route exact path={ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.ANY}>
        <EligiblePeopleEdit />
      </Route>
      <Route exact path={ROUTES.FORMS.COMPANY_GOALS.ANY}>
        <CompanyGoals />
      </Route>
      <Route exact path={ROUTES.FORMS.KPI_WEIGHTS.ANY}>
        <KPIWeights />
      </Route>
      <Route exact path={ROUTES.FORMS.COMPANY_GOALS_WEIGHTS.ANY}>
        <CompanyGoalsWeights />
      </Route>
      <Route exact path={ROUTES.FORMS.KPI_TEMPLATE.ANY}>
        <KpiTemplateForm />
      </Route>
      <Route exact path={ROUTES.FORMS.KPI_RULE.ANY}>
        <KPIRule />
      </Route>
      <Route exact path={ROUTES.FORMS.REQUISITION.ANY}>
        <Requisition />
      </Route>
      <Route exact path={ROUTES.FORMS.FUNCTION.SETTINGS}>
        <FunctionsSettingsPage />
      </Route>
      <Route exact path={ROUTES.FORMS.FUNCTION.ANY}>
        <Function />
      </Route>
      <Route exact path={ROUTES.FORMS.ASSIGN_KPIS.ANY}>
        <AssignKPIs />
      </Route>
      <Route exact path={ROUTES.FORMS.APPROVE_KPIS.ANY}>
        <ApproveKPIs />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.ANY}>
        <ImportExternalEmployees />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.ANY}>
        <ImportEmployees />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.CANDIDATES.ANY}>
        <ImportCandidates />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.TIME_OFF.ANY}>
        <ImportTimeOff />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.TIME_OFF_BALANCES.ANY}>
        <ImportTimeOffBalancesFlow />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.DOCUMENTS.ANY}>
        <ImportDocuments />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.ANY}>
        <ImportDeparmentBudget />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.BONUSES.ANY}>
        <ImportBonuses />
      </Route>
      <Route exact path={ROUTES.FORMS.RECRUITMENT_GROUPS.ANY}>
        <RecruitmentGroups />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.GENERAL}>
        <ReviewCycleGeneral />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.GENERAL_INFO}>
        <ReviewCycleGeneralInfoForm />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.TIMELINE}>
        <ReviewCycleTimelineForm />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES_LIST}>
        <EligibleEmployeesList />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES}>
        <EligibleEmployees />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.SCORECARDS}>
        <AdditionalScorecardsForm />
      </Route>
      <Route exact path={ROUTES.FORMS.REVIEW_CYCLES.GRADE}>
        <GradeLogicForm />
      </Route>
      <Route exact path={ROUTES.FORMS.SKILL.ANY}>
        <PageSkill />
      </Route>
      <Route exact path={ROUTES.FORMS.VALUE.ANY}>
        <PageCultureValue />
      </Route>
      <Route exact path={ROUTES.FORMS.SENIORITY.ANY}>
        <Seniority />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.KPIS.ANY}>
        <ImportKPIs />
      </Route>
      <Route exact path={ROUTES.FORMS.WHITELIST.GENERAL}>
        <WhitelistForm />
      </Route>
      <Route exact path={ROUTES.FORMS.JOB_POSTING.ANY}>
        <JobDescriptionForm />
      </Route>
      <Route exact path={ROUTES.FORMS.JOB_POSTING_FLOW.ANY}>
        <JobPostingFlow />
      </Route>
      <Route exact path={ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.ANY}>
        <SpecialisationHiringProcess />
      </Route>
      <Route exact path={ROUTES.FORMS.PROBATION_COMMITTEE_RESULT.GENERAL}>
        <CommitteeResultForm />
      </Route>
      <Route exact path={ROUTES.FORMS.APPLICATION_CANDIDATE.ANY}>
        <ApplicationCandidate />
      </Route>
      <Route exact path={ROUTES.FORMS.NEW_CANDIDATE.ANY}>
        <NewCandidate />
      </Route>
      <Route exact path={ROUTES.FORMS.REFER_CANDIDATE.ANY}>
        <ReferCandidate />
      </Route>
      <Route exact path={ROUTES.FORMS.REFERRAL_BONUSES.ANY}>
        <ReferralBonus />
      </Route>
      <Route exact path={ROUTES.FORMS.JOB_POSTING_AUTOMATION.ANY}>
        <JobPostingAutomationRule />
      </Route>
      <Route exact path={ROUTES.FORMS.SEND_EMAIL.ANY}>
        <SendEmail />
      </Route>
      <Route exact path={ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.ANY}>
        <SendEmployeeEmail />
      </Route>
      <Route exact path={ROUTES.FORMS.KPI_TARGET.ANY}>
        <KPITarget />
      </Route>
      <Route exact path={ROUTES.FORMS.PROMOTION_NOMINATION.ANY}>
        <PromotionNomination />
      </Route>
      <Route exact path={ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL}>
        <OfferEmailPlaceholder />
      </Route>
      <Route exact path={ROUTES.FORMS.OFFER_TEMPLATE.ANY}>
        <OfferTemplate />
      </Route>
      <Route exact path={ROUTES.FORMS.LEADERSHIP}>
        <Leadership />
      </Route>
      <Route exact path={ROUTES.FORMS.CANDIDATE_ADD_DOCUMENT}>
        <CandidateAddDocument />
      </Route>
      <Route exact path={ROUTES.FORMS.OFFER_CREATION.ANY}>
        <OfferCreation />
      </Route>
      <Route exact path={ROUTES.FORMS.ADD_SERVICE_ACCOUNT.ANY}>
        <AddServiceAccount />
      </Route>
      <Route exact path={ROUTES.FORMS.ACCESS_MANAGEMENT_USER.ANY}>
        <AccessManagementUser />
      </Route>
      <Route exact path={ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.ANY}>
        <AccessManagementGroup />
      </Route>
      <Route exact path={ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.ANY}>
        <AccessManagementPermission />
      </Route>
      <Route exact path={ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUP.ANY}>
        <AssignUserToPermissionGroup />
      </Route>
      <Route exact path={ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUPS.ANY}>
        <AssignUserToPermissionGroups />
      </Route>
      <Route exact path={ROUTES.FORMS.ASSIGN_PERMISSION_GROUP_TO_USER.ANY}>
        <AssignPermissionGroupToUser />
      </Route>
      <Route exact path={ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION.ANY}>
        <AssignUserToPermission />
      </Route>
      <Route exact path={ROUTES.FORMS.GROUP_ACCESS_REQUEST.ANY}>
        <RequestAccess />
      </Route>
      <Route exact path={ROUTES.FORMS.CV_SCREENING}>
        <CVScreening />
      </Route>
      <Route exact path={ROUTES.FORMS.CV_SCREENING_SUMMARY}>
        <CvScreeningSummary />
      </Route>
      <Route exact path={ROUTES.FORMS.COMMUNICATION_GROUPS.ANY}>
        <CommunicationGroups />
      </Route>
      <Route exact path={ROUTES.FORMS.ADD_INTERVIEW_ROUND.ANY}>
        <AddInterviewRound />
      </Route>
      <Route exact path={ROUTES.FORMS.ROADMAP.ANY}>
        <RoadmapForm />
      </Route>
      <Route exact path={ROUTES.FORMS.SPECIALISATIONS.GENERAL}>
        <SpecialisationSettings />
      </Route>
      <Route exact path={ROUTES.FORMS.SPECIALISATIONS.ANY}>
        <SpecialisationRole />
      </Route>
      <Route exact path={ROUTES.FORMS.MEETINGS_WITH.ANY}>
        <MeetingsWith />
      </Route>
      <Route exact path={ROUTES.FORMS.QUERY.ANY}>
        <QueryForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.ANY}>
        <ConnectionForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_REPORT.ANY}>
        <ReportForm />
      </Route>
      <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.ANY}>
        <ReportViewForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD_CREATE}>
        <DashboardSelect />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.ANY}>
        <DashboardForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.ANY}>
        <InternalDashboardForm />
      </Route>
      <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD_VIEW}>
        <DataAnalyticsInternalDashboardView />
      </Route>
      <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD}>
        <DashboardViewForm />
      </Route>
      <Route exact path={ROUTES.FEATURES.INTEGRATION.SLACK.ANY}>
        <SlackIntegration />
      </Route>
      <Route exact path={ROUTES.FEATURES.INTEGRATION.JIRA}>
        <JiraIntegration />
      </Route>
      <Route exact path={ROUTES.FEATURES.INTEGRATION.GOOGLE}>
        <GoogleIntegration />
      </Route>
      <Route exact path={ROUTES.FEATURES.INTEGRATION.MICROSOFT}>
        <MicrosoftIntegration />
      </Route>
      <Route exact path={ROUTES.FEATURES.INTEGRATION.DOCUSIGN}>
        <DocuSignIntegration />
      </Route>
      <Route exact path={ROUTES.FEATURES.INTEGRATION.DOCUSIGN_CONFIRMATION}>
        <DocuSignConfirmation />
      </Route>
      <Route
        exact
        path={ROUTES.FORMS.BULK_EDIT_EMPLOYEES.ANY}
        component={EmployeesBulkEdit}
      />
      <Route
        exact
        path={ROUTES.FORMS.EMPLOYEES_BULK_IMPORT}
        component={EmployeesBulkImport}
      />
      <Route
        exact
        path={ROUTES.FORMS.EMPLOYEES_ORG_CHART}
        component={EmployeesOrgChart}
      />
      <Route exact path={ROUTES.FORMS.BOOKING_LINKS}>
        <BookingLinksPage />
      </Route>
      <Route exact path={ROUTES.FORMS.CRM}>
        <CRMPage />
      </Route>
      <Route exact path={ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.ANY}>
        <BulkActionsPage />
      </Route>
      <Route exact path={ROUTES.FORMS.EMAIL_TEMPLATES}>
        <EmailTemplatesPage />
      </Route>
      <Route exact path={ROUTES.FORMS.STAGES_BANK}>
        <StagesBankPage />
      </Route>
      <Route exact path={ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATES}>
        <InterviewScorecardTemplatesPage />
      </Route>
      <Route exact path={ROUTES.FORMS.OFFER_FORMS.ANY}>
        <OffersPage />
      </Route>
      <Route
        exact
        path={ROUTES.FORMS.BULK_EDIT_CONTRACTS.ANY}
        component={ContractsBulkEdit}
      />
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.ANY}>
        <ImportCompensationBands />
      </Route>
      <Route exact path={ROUTES.FORMS.BULK_EDIT_COMPENSATION_BANDS}>
        <CompensationBandsBulkEdit />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.CONTRACTS.ANY}>
        <ImportContracts />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.GOALS.ANY}>
        <ImportGoals />
      </Route>
      <Route exact path={ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.ANY}>
        <EmployeeOnboardingTemplate />
      </Route>
      <Route
        exact
        path={ROUTES.FORMS.PAY_GROUP_BULK_EDIT}
        component={PayrollDefinitionsBulkEdit}
      />
      <Route exact path={ROUTES.FORMS.OPTION_EXERCISE.ANY} component={OptionExercise} />
      <Route exact path={ROUTES.PREFERENCES.ANY} component={UserPreferences} />
      <Route exact path={ROUTES.FORMS.CONTRACT_TYPE.ANY}>
        <ContractType />
      </Route>
      <Route path={ROUTES.FORMS.DATA_RETENTION.ANY}>
        <DataRetentionForm />
      </Route>
      <Route path={ROUTES.FORMS.INTERVIEW_AVAILABILITY}>
        <InterviewAvailabilityTablePage />
      </Route>
      <Route exact path={ROUTES.PAGE_404}>
        <Page404 />
      </Route>
      <Route exact path={ROUTES.ANY}>
        <InternalRedirect to={ROUTES.PAGE_404} />
      </Route>
    </Switch>
  )
}

export default NewFormSwitch
