import React from 'react'
import { ScoreType, ViewType } from '../types'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  engagementQuestionsActionsColumn,
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsNpsDistributionColumn,
  engagementResultsNpsScoreColumn,
  engagementResultsQuestionCategoryNameColumn,
  engagementResultsQuestionNameColumn,
  engagementResultsQuestionTypeColumn,
  engagementResultsTrendAverageScoreColumn,
  engagementResultsTrendNpsScoreColumn,
} from '@src/constants/columns/engagementResults'
import { Token, Box } from '@revolut/ui-kit'
import { useTable } from '@src/components/Table/hooks'
import { getSurveyAdminResultQuestionsTableRequests } from '@src/api/engagement'
import { EngagementResultInterface } from '@src/interfaces/engagement'

interface Props {
  view: ViewType
  scoreType: ScoreType
  selectedRound: IdAndName
  surveyId: number
}

const trendColumnTooltip = (
  <Box color={Token.color.background} p="s-8" minWidth={250}>
    This indicates how much the score for this question has increased or decreased since
    the last time it was asked to employees
  </Box>
)

const getRows = (surveyId: number) => ({
  cells: [
    { ...engagementResultsQuestionNameColumn, width: 350 },
    { ...engagementResultsQuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsQuestionTypeColumn, width: 120 },
    { ...engagementResultsAverageScoreColumn, width: 95 },
    { ...engagementResultsNpsScoreColumn, width: 95 },
    { ...engagementResultsAverageDistributionColumn, width: 300 },
    { ...engagementResultsNpsDistributionColumn, width: 300 },
    {
      ...engagementResultsTrendAverageScoreColumn,
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
    {
      ...engagementResultsTrendNpsScoreColumn,
      width: 65,
      headerTooltip: trendColumnTooltip,
    },
    { ...engagementQuestionsActionsColumn(surveyId), width: 40 },
  ],
})

export const ResultQuestions = ({ view, scoreType, selectedRound, surveyId }: Props) => {
  const table = useTable<EngagementResultInterface>(
    getSurveyAdminResultQuestionsTableRequests({ surveyId }),
    [
      {
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      },
    ],
  )

  const hiddenCells = {
    [engagementResultsTrendAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsAverageDistributionColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsNpsScoreColumn.idPoint]: scoreType === 'average',
    [engagementResultsNpsDistributionColumn.idPoint]: scoreType === 'average',
    [engagementResultsTrendNpsScoreColumn.idPoint]: scoreType === 'average',
  }

  return view === 'table' ? (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultQuestions}
      row={getRows(surveyId)}
      rowHeight="large"
      hiddenCells={hiddenCells}
      {...table}
    />
  ) : null
}
