// TODO: this component should be moved to UI kit, but we have to release it asap DSDEV-3312-new-weekly-large-calendar
// it's already reviewed by UI kit but having some issues with build https://bitbucket.org/revolut/revolut-ui-kit/pull-requests/2867

import '@toast-ui/calendar/dist/toastui-calendar.min.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import type { Options } from '@toast-ui/calendar'
import Calendar from '@toast-ui/calendar'
import styled, { css } from 'styled-components'
import { diffPeriod, getAllDaysBetweenDates, getFormattedTime } from './date'
import { ActionButton, Box, HStack, IconButton, Text, Token } from '@revolut/ui-kit'

import { LargeWeeklyCalendarTheme } from './theme'
import {
  DateType,
  LargeWeeklyCalendarChangeEventInterface,
  LargeWeeklyCalendarClickEventInterface,
  LargeWeeklyCalendarCreateEventInterface,
  LargeWeeklyCalendarEventInterface,
} from './types'
import { HeaderCellWrapper } from '@components/Table/HeaderCell'
import { TableTypes } from '@src/interfaces/table'
import { defaultTheme } from '@src/styles/theme'

export interface LargeWeeklyCalendarProps {
  events: LargeWeeklyCalendarEventInterface[]
  onAddEvent: (event: LargeWeeklyCalendarCreateEventInterface) => void
  onClickEvent: (params: LargeWeeklyCalendarClickEventInterface) => void
  onChangeEvent: (event: LargeWeeklyCalendarChangeEventInterface) => void
  onSwitchWeek?: (startDate: Date, endDate: Date) => void
}

export const SECOND = 1000
export const MINUTE = SECOND * 60

const Header = styled.div<{
  useWindowScroll?: boolean
  top?: number
  width?: number
}>`
  overflow-x: hidden;
  width: ${({ width }) => (width ? `${width}px` : undefined)};
  display: flex;
  border: 1px solid ${Token.color.greyTone8};
  z-index: ${defaultTheme.zIndex.aboveMain + 2};
  position: ${({ useWindowScroll }) => (useWindowScroll ? 'sticky' : 'relative')};
  top: ${({ top }) => top ?? 0}px;
  background: ${Token.color.greyTone2};
  flex: 0 0 auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Wrap = styled(Box)`
  .toastui-calendar-week-view-day-names {
    display: none;
  }

  .toastui-calendar-allday {
    border-left: 1px solid ${Token.color.greyTone8};
  }

  .toastui-calendar-time {
    border-left: 1px solid ${Token.color.greyTone8};
    border-bottom: 1px solid ${Token.color.greyTone8};
  }
`

export const getDateFromEventDate = (eventDate?: DateType) => {
  if (!eventDate) {
    return null
  }

  if (typeof eventDate === 'string' || typeof eventDate === 'number') {
    return new Date(eventDate)
  }

  if ('toDate' in eventDate) {
    return eventDate.toDate()
  }

  return eventDate
}

const DraggableMatrixCss = css`
  right: -3.5px;
`

const Draggable = styled.div<{ type: TableTypes }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  width: 7px;
  cursor: col-resize;
  ${({ type }) => type === TableTypes.Matrix && DraggableMatrixCss};

  &::after {
    content: '';
    width: 1px;
    transition: background-color 200ms ease-in-out;
    height: 16px;
    background-color: ${Token.color.greyTone50};
  }
`

const TableCellShow = styled.div<{ centered?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-right: ${props => (props.centered ? 0 : 12)}px;

  &:hover > ${Draggable}::after {
    opacity: 1;
  }
`

const Title = styled.div<{ active: boolean; centered?: boolean }>`
  padding: ${props => (props.centered ? '10px 0' : '10px 0 10px 16px')};
  flex: 1;
  text-align: ${props => (props.centered ? 'center' : 'inherit')};
  text-transform: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: ${props => (props.active ? Token.color.blue_60 : Token.color.greyTone50)};
`

const HEADER_HEIGHT_PX = 80
const LONG_EVENT_MINUTES_THRESHOLD = 45

export function LargeWeeklyCalendar({
  onAddEvent,
  onClickEvent,
  onChangeEvent,
  onSwitchWeek,
  events,
}: LargeWeeklyCalendarProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const wrapRef = useRef(null)
  const calendarRef = useRef<Calendar | null>(null)
  const weekSwitcherRef = useRef<HTMLDivElement | null>(null)
  const mounted = useRef(false)

  const [columns, setColumns] = useState<{ id: string; title: string; width?: number }[]>(
    [],
  )

  const updateHeader = useCallback(() => {
    if (!calendarRef.current) {
      return
    }

    const dates = getAllDaysBetweenDates(
      calendarRef.current.getDateRangeStart().toDate(),
      calendarRef.current.getDateRangeEnd().toDate(),
    )

    setColumns([
      {
        title: '',
        width: 60,
        id: 'day',
      },
      ...dates.map(date => ({
        title: date.toLocaleDateString('en-EN', {
          day: 'numeric',
          month: 'short',
        }),
        id: date.toLocaleDateString('en-EN', {
          day: 'numeric',
          month: 'short',
        }),
      })),
    ])
  }, [setColumns])

  useEffect(() => {
    const options: Options = {
      usageStatistics: false,
      defaultView: 'week',
      week: {
        taskView: false,
        eventView: true,
        // TODO: add to ui-kit component
        // set start day of the week to monday
        startDayOfWeek: 1,
      },
      template: {
        alldayTitle() {
          return ''
        },
        time(event: LargeWeeklyCalendarEventInterface) {
          const startDate = getDateFromEventDate(event.start)
          const endDate = getDateFromEventDate(event.end)
          const isLongEvent =
            startDate &&
            endDate &&
            diffPeriod(endDate, startDate, MINUTE) > LONG_EVENT_MINUTES_THRESHOLD
          const textColor = event.color || Token.color.black

          if (isLongEvent) {
            const timeColor = event.color || Token.color.greyTone50
            const titleLabel = event.title
              ? `<span style='color: ${textColor}; font-size: 12px; white-space: pre-wrap; line-height: 18px;'>${event.title}</span>`
              : ''
            const timeLabel =
              startDate && endDate
                ? `<div style="color: ${timeColor}; font-size: 10px; padding-top: 2px;">${getFormattedTime(
                    startDate,
                  )} - ${getFormattedTime(endDate)}</div>`
                : ''

            return `${titleLabel}${timeLabel}`
          }

          return `<span style="color: ${textColor}; white-space: pre-wrap; font-size: 12px;">${
            event.title
          }${event.title && startDate ? ', ' : ''}${
            startDate ? getFormattedTime(startDate) : ''
          }</span>`
        },
      },
      theme: LargeWeeklyCalendarTheme,
    }

    if (ref.current) {
      calendarRef.current = new Calendar(ref.current, options)
      mounted.current = true
      updateHeader()
    }

    return () => {
      calendarRef.current?.destroy()
      mounted.current = false
    }
  }, [updateHeader])

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.clear()
      calendarRef.current.createEvents(
        events.map(event => ({
          backgroundColor: Token.color.actionBlue,
          dragBackgroundColor: Token.color.actionBlue_70,
          borderColor: 'none',
          ...event,
          title: event.title,
        })),
      )
    }
  }, [events])

  useEffect(() => {
    if (!calendarRef.current) {
      return () => {}
    }

    calendarRef.current.on('selectDateTime', event => {
      onAddEvent(event)
      calendarRef.current?.clearGridSelections()
    })

    calendarRef.current.on('clickEvent', onClickEvent)

    calendarRef.current.on('beforeUpdateEvent', onChangeEvent)

    return () => {
      // when the library destroys the instance it removes all properties from the internal object, leading to issues when we call calendarRef.current?.off right after we destroyed the instance
      if (mounted.current) {
        calendarRef.current?.off('selectDateTime')
        calendarRef.current?.off('clickEvent')
        calendarRef.current?.off('beforeUpdateEvent')
      }
    }
  }, [onAddEvent, onClickEvent, onChangeEvent])

  const onHandleSwitchWeek = () => {
    updateHeader()

    if (calendarRef.current && onSwitchWeek) {
      onSwitchWeek(
        calendarRef.current.getDateRangeStart().toDate(),
        calendarRef.current.getDateRangeEnd().toDate(),
      )
    }
  }

  return (
    <>
      <Wrap ref={wrapRef} height="100%" minWidth={600}>
        {calendarRef.current && (
          <HStack mb="s-16" gap="s-16" align="center" ref={weekSwitcherRef}>
            <IconButton
              useIcon="ChevronLeft"
              onClick={() => {
                calendarRef.current?.prev()
                onHandleSwitchWeek()
              }}
            />
            <IconButton
              useIcon="ChevronRight"
              onClick={() => {
                calendarRef.current?.next()
                onHandleSwitchWeek()
              }}
            />
            <Text variant="heading3">
              {calendarRef.current
                .getDateRangeStart()
                .toDate()
                .toLocaleString('default', { month: 'long', year: 'numeric' })}
            </Text>
            <ActionButton
              size="xs"
              onClick={() => {
                calendarRef.current?.today()
                onHandleSwitchWeek()
              }}
            >
              Today
            </ActionButton>
          </HStack>
        )}
        <Header>
          {columns.map(cell => {
            return (
              <HeaderCellWrapper
                key={cell.id}
                type={TableTypes.Adjustable}
                width={cell.width}
                rowHeight="medium"
              >
                <TableCellShow>
                  <Title active={false}>{cell.title}</Title>
                </TableCellShow>
              </HeaderCellWrapper>
            )
          })}
        </Header>

        <div
          ref={ref}
          style={{
            height: `calc(100% - ${HEADER_HEIGHT_PX}px)`,
          }}
        />
      </Wrap>
    </>
  )
}
