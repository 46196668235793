import React from 'react'
import {
  Tag,
  Item,
  Avatar,
  Text,
  Cell,
  Icon,
  VStack,
  Token,
  ItemSkeleton,
  MoreBarSkeleton,
  DetailsCellSkeleton,
} from '@revolut/ui-kit'
import {
  useGetEngagementSurvey,
  useRefetchEngagementSurveyRounds,
} from '@src/api/engagement'
import { mapSurveyRoundStatusToColorV2 } from '@src/apps/People/Engagement/helpers'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { SurveyActions } from './SurveyActions'
import { SurveyOverview } from './SurveyOverview'
import { SurveyResults } from './SurveyResults'

const tabsConfig = [
  {
    title: 'Overview',
    icon: <Icon name="Profile" size={16} />,
    path: ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_OVERVIEW,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_OVERVIEW,
    component: SurveyOverview,
  },
  {
    title: 'Results',
    icon: <Icon name="Questionnaire" size={16} />,
    path: ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.ANY,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.CATEGORIES,
    component: SurveyResults,
  },
]

export const SurveyDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { data: surveyData, isLoading, refetch } = useGetEngagementSurvey(id)
  const { refetchEngagementSurveyRounds } = useRefetchEngagementSurveyRounds(id)

  const tabs = tabsConfig.map(tab => ({ ...tab, url: pathToUrl(tab.url, { id }) }))

  if (!id || (!isLoading && !surveyData)) {
    return <Redirect to={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD} />
  }

  const onActionPerformed = () => {
    refetch()
    refetchEngagementSurveyRounds()
  }

  return (
    <PageWrapper>
      <PageHeader title={null} backUrl={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD} />
      <PageBody maxWidth={Token.breakpoint.xl} rowGap="s-16">
        <Cell>
          <VStack gap="s-16">
            {isLoading ? (
              <ItemSkeleton>
                <ItemSkeleton.Avatar />
                <ItemSkeleton.Content>
                  <ItemSkeleton.Title />
                  <ItemSkeleton.Description />
                </ItemSkeleton.Content>
              </ItemSkeleton>
            ) : (
              <Item p="0px">
                <Item.Avatar size={56}>
                  <Avatar useIcon="Message" size={56} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Text variant="h5">{surveyData.title}</Text>
                  </Item.Title>
                  <Item.Description pt="s-4">
                    {surveyData.round_status && (
                      <Tag
                        variant="outlined"
                        color={mapSurveyRoundStatusToColorV2(surveyData)}
                      >
                        {surveyData.round_status.name}
                      </Tag>
                    )}
                  </Item.Description>
                </Item.Content>
              </Item>
            )}
            {isLoading ? (
              <MoreBarSkeleton />
            ) : (
              <SurveyActions survey={surveyData} refetch={onActionPerformed} />
            )}
          </VStack>
        </Cell>
        <TabBarNavigation tabs={tabs} />
        {isLoading ? (
          <DetailsCellSkeleton />
        ) : (
          <Switch>
            {tabs.map(tab => (
              <Route path={tab.path} key={tab.title}>
                <tab.component survey={surveyData} />
              </Route>
            ))}
          </Switch>
        )}
      </PageBody>
    </PageWrapper>
  )
}
