import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '@src/api/index'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  WorkScheduleApprovalsStepInterface,
  WorkScheduleBasicStepInterface,
  WorkScheduleCustomApproversListItemInterface,
  WorkScheduleEligibilityStepInterface,
  WorkScheduleExtraHoursStepInterface,
  WorkScheduleInterface,
  WorkScheduleListItemInterface,
  WorkScheduleReviewCustomApproversListItemInterface,
  WorkScheduleReviewCustomApproversStats,
  WorkScheduleReviewEligibleEmployeesInterface,
  WorkScheduleReviewEligibleEmployeesStatsInterface,
  WorkScheduleReviewStepInterface,
} from '@src/interfaces/workSchedule'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { TimeOffTableFilterInterface } from '@src/interfaces/timeOff'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'

export const workScheduleBasicRequests: RequestInterfaceNew<WorkScheduleBasicStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/basicStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/basicStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/basicStep`, data),
  }

export const useGetWorkScheduleBasics = (id: string | undefined) =>
  useFetch<WorkScheduleBasicStepInterface>(
    id ? `${API.TIME_OFF}/workSchedules/basicStep/${id}` : null,
  )

export const workScheduleExtraHoursRequests: RequestInterfaceNew<WorkScheduleExtraHoursStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/extraHoursStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.TIME_OFF}/workSchedules/extraHoursStep/${id}`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/extraHoursStep`, data),
  }

export const useGetWorkScheduleExtraHours = (id: string | undefined) =>
  useFetch<WorkScheduleExtraHoursStepInterface>(
    id ? `${API.TIME_OFF}/workSchedules/extraHoursStep/${id}` : null,
  )

export const workScheduleApprovalsRequests: RequestInterfaceNew<WorkScheduleApprovalsStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/approvalsStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/approvalsStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/approvalsStep`, data),
  }

export const useGetWorkScheduleApprovals = (id: string | undefined) =>
  useFetch<WorkScheduleApprovalsStepInterface>(
    id ? `${API.TIME_OFF}/workSchedules/approvalsStep/${id}` : null,
  )

export const workScheduleEligibilityRequests: RequestInterfaceNew<WorkScheduleEligibilityStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/eligibilityStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.TIME_OFF}/workSchedules/eligibilityStep/${id}`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/eligibilityStep`, data),
  }

export const useGetWorkScheduleEligibility = (id: string | undefined) =>
  useFetch<WorkScheduleEligibilityStepInterface>(
    id ? `${API.TIME_OFF}/workSchedules/eligibilityStep/${id}` : null,
  )

export const workScheduleReviewEligibilityRequests: RequestInterfaceNew<WorkScheduleEligibilityStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/reviewStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/reviewStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/reviewStep`, data),
  }

export const useGetWorkScheduleReviewEligibility = (id: string | undefined) =>
  useFetch<WorkScheduleReviewStepInterface>(
    id ? `${API.TIME_OFF}/workSchedules/reviewStep/${id}` : null,
  )

export const getWorkScheduleEligibilityCustomFiltersWithoutHandling = (
  scheduleId: string | number,
  filterId: number,
) =>
  apiWithoutHandling.get<DynamicGroupInerface>(
    `${API.TIME_OFF}/workSchedules/eligibilityStep/${scheduleId}/customFilter/${filterId}`,
  )

export const workScheduleEligibilityCustomFiltersRequests = {
  get: async (scheduleId: string | number, filterId: number) =>
    api.get<DynamicGroupInerface>(
      `${API.TIME_OFF}/workSchedules/eligibilityStep/${scheduleId}/customFilter/${filterId}`,
    ),
  update: async (
    data: { table_filters: TableFilter },
    scheduleId: string | number,
    filterId: string | number,
  ) =>
    api.patch<DynamicGroupInerface>(
      `${API.TIME_OFF}/workSchedules/eligibilityStep/${scheduleId}/customFilter/${filterId}`,
      data,
    ),
  submit: async (data: TimeOffTableFilterInterface, scheduleId: string | number) =>
    api.post<DynamicGroupInerface>(
      `${API.TIME_OFF}/workSchedules/eligibilityStep/${scheduleId}/customFilter`,
      data,
    ),
}

export const getWorkScheduleReviewEligibleEmployeesTableRequests = (
  scheduleId: number,
) => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<WorkScheduleReviewEligibleEmployeesInterface>>(
      `${API.TIME_OFF}/workSchedules/reviewStep/${scheduleId}/eligibleEmployees`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<WorkScheduleReviewEligibleEmployeesStatsInterface>(
      `${API.TIME_OFF}/workSchedules/reviewStep/${scheduleId}/stats`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
})

export const workScheduleTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<WorkScheduleListItemInterface>>(
      `${API.TIME_OFF}/workSchedules`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
}

export const workScheduleRequests: RequestInterfaceNew<WorkScheduleInterface> = {
  get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules`, data),
}

export const archiveWorkSchedule = (id: number) =>
  api.post<WorkScheduleInterface>(`${API.TIME_OFF}/workSchedules/${id}/archive`)

export const workScheduleCustomApproversTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<WorkScheduleCustomApproversListItemInterface>>(
      `${API.TIME_OFF}/customApproversFlows`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
}

export const workScheduleCustomApproversRequests: RequestInterfaceNew<WorkScheduleBasicStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/customApproversFlows/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/customApproversFlows/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/customApproversFlows`, data),
  }

export const useGetWorkScheduleCustomApprovers = (id: string | undefined) =>
  useFetch<WorkScheduleBasicStepInterface>(
    id ? `${API.TIME_OFF}/customApproversFlows/${id}` : null,
  )

export const getWorkScheduleReviewCustomApproversTableRequests = (
  id: string | number,
) => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<WorkScheduleReviewCustomApproversListItemInterface>>(
      `${API.TIME_OFF}/customApproversFlows/${id}/eligibleEmployees`,
      { params: filterSortPageIntoQuery(sortBy, filters, page) },
    ),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<WorkScheduleReviewCustomApproversStats>(
      `${API.TIME_OFF}/customApproversFlows/${id}/eligibleEmployees/stats`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
})

export const activateWorkScheduleCustomApprovers = (id: number | string) =>
  api.post<WorkScheduleInterface>(`${API.TIME_OFF}/customApproversFlows/${id}/activate`)

export const archiveWorkScheduleCustomApprovers = (id: number | string) =>
  api.post<WorkScheduleInterface>(`${API.TIME_OFF}/customApproversFlows/${id}/archive`)

export const getCustomApproversFlowEligibilityCustomFiltersWithoutHandling = (
  filterId: number,
) =>
  apiWithoutHandling.get<DynamicGroupInerface>(
    `${API.TIME_OFF}/customApproversFlows/customFilter/${filterId}`,
  )

export const customApproversFlowEligibilityCustomFiltersRequests = {
  get: async (filterId: number) =>
    api.get<DynamicGroupInerface>(
      `${API.TIME_OFF}/customApproversFlows/customFilter/${filterId}`,
    ),
  update: async (data: { table_filters: TableFilter }, filterId: number | string) =>
    api.patch<DynamicGroupInerface>(
      `${API.TIME_OFF}/customApproversFlows/customFilter/${filterId}`,
      data,
    ),
  submit: async (data: {
    table_filters: TableFilter
    eligibility_group_type: 'employee_filters'
  }) =>
    api.post<DynamicGroupInerface>(
      `${API.TIME_OFF}/customApproversFlows/customFilter`,
      data,
    ),
}

export const activateWorkSchedule = (id: number | string) =>
  api.post<WorkScheduleInterface>(`${API.TIME_OFF}/workSchedules/${id}/activate`)
